import React from "react";
import { Row, Col, Panel } from "rsuite";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight } from "react-icons/bi";
export default function ContentfulPageQuestionOverviewFiltered() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageQuestion (
        filter: { category: { eq: "Medicijnen en bijwerkingen" } }
      ) {
        nodes {
          title
          id
          url
          category
          subcategory
        }
      }
    }
  `);

  const groupedItems = data.allContentfulPageQuestion.nodes.reduce(
    (items, item) => {
      if (items[item.category]) {
        items[item.category].push(item);
      } else {
        items[item.category] = [item];
      }
      return items;
    },
    {}
  );

  return (
    <>
      {Object.keys(groupedItems).map((node) => {
        return (
            <>
             {groupedItems[node]
                  .sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })
                  .map((node) => {
                    return (
                      <Row key={node.title}>
                        <Col xs={24}>
                          <Link to={`/patient/${node.url}`}>
                            <Panel
                              style={{
                                backgroundColor: "white",
                                marginBottom: "10px",
                              }}
                              bordered
                            >
                              <h5 style={{ fontWeight: "bold", width: "95%" }}>
                                {node.title}{" "}
                                <Icon
                                  as={BiArrowToRight}
                                  size="1.25em"
                                  style={{
                                    marginRight: "10px",
                                    color: "#ee9a94",
                                  }}
                                />
                              </h5>
                            </Panel>
                          </Link>
                        </Col>
                      </Row>
                    );
                  })}
            </>
        );
      })}
    </>
  );
}
